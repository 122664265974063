
/* Single image */
import simg1 from '../images/service-single/1.jpg'
import simg2 from '../images/service-single/2.jpg'
import simg3 from '../images/service-single/3.jpg'

const Services = [
    {
        id: 1,
        icon: 'flaticon-doctor',
        simage: simg1,
        title: 'Toxinas',
        description: 'Las toxinas, como el Botox, relajan los músculos faciales para reducir arrugas dinámicas como las líneas de expresión y patas de gallo. Ideal para un aspecto rejuvenecido y natural. Duración: 3-6 meses.',
        slug: '#'
    },
    {
        id: 2,
        icon: 'flaticon-businesswoman',
        simage: simg2,
        title: 'Rellenos/Filler',
        description: 'Los rellenos dérmicos, como el ácido hialurónico, restauran volumen en el rostro, rellenan arrugas profundas y mejoran los contornos faciales, aportando hidratación y firmeza. Duración de 1 a 2 años.',
        slug: '#'
    },
    {
        id: 3,
        icon: 'flaticon-star',
        simage: simg3,
        title: 'Bioestimuladores',
        description: 'Los bioestimuladores, como Sculptra, estimulan la producción de colágeno, mejorando la firmeza y elasticidad de la piel de forma gradual y natural. Duración: 1-2 años.',
        slug: '#'
    },

];
export default Services;