import React from 'react';
import CountUp from 'react-countup';

const FunFact = (props) => {

    return (
        <section className={"" + props.hclass} >
            <div className="container">
                <div className="row">
                    <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="item">
                            <i className="flaticon-doctor" aria-label='Doctor Lumina Glow'></i>
                            <h3><CountUp end={750} enableScrollSpy />+</h3>
                            <p>Ventas Éxitosas</p>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="item" aria-label='Cliente Lumina Glow'>
                            <i className="flaticon-businesswoman"></i>
                            <h3><CountUp end={65} enableScrollSpy />+</h3>
                            <p> Clientes Felices</p>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="item">
                            <i className="flaticon-award" aria-label='Experiencia Lumina Glow'></i>
                            <h3><CountUp end={10} enableScrollSpy />+</h3>
                            <p>Años de Experiencia</p>
                        </div>
                    </div>
                    <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="item">
                            <i className="flaticon-customer-care" aria-label='Soporte Lumina Glow'></i>
                            <h3><CountUp end={24} enableScrollSpy />/<CountUp end={7} enableScrollSpy /></h3>
                            <p>Soporte a Clientes</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default FunFact;