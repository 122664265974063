import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Contactpage from '../../components/Contactpage/Contactpage'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import PageHead from '../../components/SEO/PageHead';

import Logo from '../../images/logo-2.svg'

const ContactPage = () => {
    return (
        <Fragment>
            <PageHead
                title="Contacto"
                description="Distribuidora de productos para la Medicina Estética. Nos especializamos en la distribución de las mejores marcas de toxina botulínica proporcionando soluciones de compra rápidas y eficientes."
                keywords="Medicina Estética, Toxina Botulínica, Botox, Lumina Monterrey"
                image='../../images/logo.svg'
                url="https://luminaglow.mx"
                subject="Productos de Lumina Glow"
            />
            <Navbar hclass={'wpo-site-header wpo-site-header-s2'} Logo={Logo} />
            <PageTitle pageTitle={'Contacto'} pagesub={'Contacto'} />
            <Contactpage />
            <Footer hclass={'wpo-site-footer_s2'} />
            <Scrollbar />
        </Fragment>
    )
};
export default ContactPage;

