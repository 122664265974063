import React, { Fragment, useState } from 'react';
import { connect } from "react-redux";
import PageTitle from '../../components/pagetitle/PageTitle'
import { addToCart } from "../../store/actions/action";
import ShopProduct from '../../components/ShopProduct';
import api from "../../api";
import Navbar from '../../components/Navbar/Navbar';
// import CtafromSection from '../../components/CtafromSection/CtafromSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Logo from '../../images/logo-2.svg';
import PageHead from '../../components/SEO/PageHead';

const ShopPage = ({ addToCart }) => {


    const productsArray = api();
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 6;

    const totalProducts = productsArray.length;
    const totalPages = Math.ceil(totalProducts / productsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    };
    const currentProducts = productsArray.slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage);

    const addToCartProduct = (product, qty = 1) => {
        addToCart(product, qty);
    };


    return (
        <Fragment>
            <PageHead
                title="Lumina | Productos"
                description="Distribuidora de productos para la Medicina Estética. Nos especializamos en la distribución de las mejores marcas de toxina botulínica proporcionando soluciones de compra rápidas y eficientes."
                keywords="Medicina Estética, Toxina Botulínica, Botox, Dysport, Linurase, Restylane"
                image='../../images/logo.svg'
                url="https://luminaglow.mx"
                subject="Productos de Lumina Glow"
            />
            <Navbar hclass={'wpo-site-header wpo-site-header-s2'} Logo={Logo} />
            <PageTitle pageTitle={'Productos'} />
            <section className="shop_section section-padding">
                <div className="container">
                    <div className="row" style={{marginRight: '0px', marginLeft:'0px'}}>
                        <div className="col-lg-12">
                            <ShopProduct
                                addToCartProduct={addToCartProduct}
                                products={currentProducts}
                            />
                            <div className="pagination-wrapper pagination-wrapper-center">
                                <ul className="pg-pagination">
                                    <li>
                                        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} aria-label="Previous">
                                            <i className="ti-angle-left"></i>
                                        </button>
                                    </li>
                                    {[...Array(totalPages)].map((_, index) => (
                                        <li key={index} className={currentPage === index + 1 ? 'active' : ''}>
                                            <button onClick={() => handlePageChange(index + 1)}>
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                    <li>
                                        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} aria-label="Next">
                                            <i className="ti-angle-right"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <CtafromSection hclass={'ctafrom_section'} /> */}
            <Footer hclass={'wpo-site-footer'} />
            <Scrollbar />
        </Fragment>
    )
};

export default connect(null, { addToCart })(ShopPage);