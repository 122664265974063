import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const PageHead = ({ title, description, keywords, image, url, subject }) => {
    return (
        <Helmet>
            <title>{title || "Lumina | Productos Estéticos"}</title>
            <meta name="description" content={description || 'Distribuidora de productos para la Medicina Estética. Nos especializamos en la distribución de las mejores marcas de toxina botulínica proporcionando soluciones de compra rápidas y eficientes.'} />
            <meta name="keywords" content={keywords || "Medicina Estética, Toxina Botulínica, Botox"} />
            <meta name="author" content="Lumina & Webless Project" />
            <meta property="og:title" content={title || "Lumina | Productos Estéticos"} />
            <meta property="og:description" content={description || "Distribuidora de productos para la Medicina Estética. Nos especializamos en la distribución de las mejores marcas de toxina botulínica proporcionando soluciones de compra rápidas y eficientes."} />
            <meta property="og:image" content={image || "/images/logo.svg"} />
            <meta property="og:url" content={url || "https://luminaglow.mx"} />
            <meta property="og:type" content="website" />
            <meta name="subject" content={subject || "Productos Estéticos"} />
            <meta name="language" content="es" />
            <meta name="revisit-after" content="3 days" />
            <meta name="robots" content="index,follow" />
        </Helmet>
    );
};

PageHead.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    image: PropTypes.string,
    url: PropTypes.string,
    subject: PropTypes.string,
};

export default PageHead;
