import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.svg";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const Footer = (props) => {
  return (
    <footer className={"" + props.hclass}>
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="rowespecial"  style={{marginRight: '0px', marginLeft:'0px'}}>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img style={{ width: '40%' }} src={'https://d1m57hjtnyqfpw.cloudfront.net/WhatsApp_Image_2024-10-03_at_19.39.39-removebg-preview.png'} alt="blog" />
                </div>
                <p>
                    Respaldados por la confianza de clínicas y médicos de todo el país. 
                    Nuestro compromiso es ofrecer productos innovadores y seguros que 
                    potencien los resultados de sus tratamientos.
                </p>
                <div className="social-widget">
                  <ul>
                    {/* <li><Link onClick={ClickHandler} to="#"><i className="flaticon-facebook-app-symbol"></i></Link></li>
                                        <li><Link onClick={ClickHandler} to="#"><i className="flaticon-twitter"></i></Link></li>
                                        <li><Link onClick={ClickHandler} to="#"><i className="flaticon-linkedin"></i></Link></li> */}
                    <li>
                      <a
                        href="https://www.instagram.com/luminaglow.mx/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="flaticon-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Quick Links</h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/home">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/shop">
                      Productos
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/faq">
                      Información
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/contact">
                      Contacto
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget s2">
                <div className="widget-title">
                  <h3>Productos</h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/shop-single/nabota">
                      Nabota
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/shop-single/botox">
                      Botox
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/shop-single/dysport">
                      Dysport
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/faq">
                      Faq
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget contact-widget">
                <div className="widget-title">
                  <h3>Contacto</h3>
                </div>
                <ul>
                  <li>
                    <i className="flaticon-email"></i>
                    <span>luminaglow.ads@gmail.com</span>
                  </li>
                  <li>
                    {" "}
                    <i className="flaticon-telephone"></i>
                    <span>
                        81 2319 1534
                      <br />
                        81 1290 3301
                    </span>
                  </li>
                  <li>
                    <i className="flaticon-location-1"></i>
                    <span>
                      Monterrey, Nuevo León<br />
                      
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row g-0" >
            <div className="col col-lg-6 col-12">
              <p className="copyright">
                {" "}
                Copyright &copy; 2024 Webless Project{" "}
                <Link onClick={ClickHandler} to="/">
                  weblessproject.com
                </Link>
                <br></br>
                All Rights Reserved.
              </p>
            </div>
            <div className="col col-lg-6 col-12">
              <ul>
                <li>
                  <Link onClick={ClickHandler} to="/">
                    Privace & Policy
                  </Link>
                </li>
                <li>
                  <Link onClick={ClickHandler} to="/faq">
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
