import React from 'react';
import Ctaimg from '../../images/cta.png'

const CtaSection = (props) => {
    return (
        <section className={"" + props.tClass}>
            <div className="container">
                <div className="cta_wrapper">
                    <div className="content">
                        <div className="icon">
                            <i className="flaticon-phone-call"></i>
                        </div>
                        <div className="text">
                            <h2 aria-label='Whatsapp'>Disponibles 24/7 (WhatsApp)</h2>
                            <h3><a href="https://api.whatsapp.com/send/?phone=5218123191534&text&type=phone_number&app_absent=0" target="_blank" style={{ color: 'white', textDecoration: 'none' }} aria-label='Contacto Lumina Glow'>81 2319 1534</a></h3>
                            
                        </div>
                    </div>
                    <div className="shape-icon">
                        <i className="flaticon-24-7"></i>
                    </div>
                    <div className="image">
                        {/* <img src={Ctaimg} alt="" /> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CtaSection;