import React from 'react';
import CountUp from 'react-countup';


// image
import Ab1 from '../../images/about.jpg'
import Abd1 from '../../images/doctors/1.jpg'
import Abd2 from '../../images/doctors/2.jpg'
import Abd3 from '../../images/doctors/3.jpg'
import Abd4 from '../../images/doctors/4.jpg'
import sine from '../../images/signeture.png'

const about = (props) => {
    return (
        <section className={"" + props.hclass}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-12">
                        <div className="about_left">
                            <div className="image">
                                <img src={'https://images.pexels.com/photos/5407206/pexels-photo-5407206.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} alt="Lumina Glow Monterrey" />
                                    <span className="round-on"></span>
                                    <span className="round-two"></span>
                                    <div className="award">
                                        <div className="icon">
                                            <i className="flaticon-cup"></i>
                                        </div>
                                        <div className="text">
                                        <h2><CountUp end={10} enableScrollSpy />+</h2>
                                            <p>Años de Experiencia</p>
                                        </div>
                                    </div>
                                    <div className="doctors">
                                        <ul>
                                        <li><img src={'https://images.pexels.com/photos/5215024/pexels-photo-5215024.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} alt="Doctor de Lumina Glow Monterrey" /></li>
                                        <li><img src={'https://images.pexels.com/photos/1181690/pexels-photo-1181690.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} alt="Doctor de Lumina Glow Monterrey" /></li>
                                        <li><img src={'https://images.pexels.com/photos/3201694/pexels-photo-3201694.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} alt="Doctor de Lumina Glow Monterrey" /></li>
                                        <li><img src={'https://images.pexels.com/photos/2182970/pexels-photo-2182970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} alt="Doctor de Lumina Glow Monterrey" /></li>
                                            <li><span>95+</span></li>
                                        </ul>
                                        <h4> Testimonios</h4>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="content">
                            <h2 aria-label='Lumina Glow Monterrey'>Lumina Glow Monterrey</h2>
                            <h3>Innovación y Calidad para Profesionales </h3>

                            <p>Somos una marca regional líder en productos de rejuvenecimiento de la piel, 
                                dedicada a ofrecer soluciones especializadas y un abastecimiento eficiente para cubrir las demandas de tus pacientes.</p>
                            <p>Nos destacamos en el mercado mayorista por nuestra constante innovación, optimización y garantía de calidad, 
                                consolidándonos como tu aliado confiable en el mundo cosmético clínico.</p>
                                {/* <div className="ceo">
                                    <div>
                                        <h4>Savannah Nguyen</h4>
                                        <span>CEO & Founder of Madically</span>
                                    </div>
                                    <div>
                                        <img src={sine} alt="" />
                                    </div>
                                </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default about;