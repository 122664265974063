import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/hero/hero';
import AppointmentSection from '../../components/AppointmentSection/AppointmentSection';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import About from '../../components/about/about';
import ProcessSection from '../../components/ProcessSection/ProcessSection';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import CtaSection from '../../components/CtaSection/CtaSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import FunFact from '../../components/FunFact/FunFact';
import BlogSection from '../../components/BlogSection/BlogSection';
import CtafromSection from '../../components/CtafromSection/CtafromSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/logo.svg'
import PageHead from '../../components/SEO/PageHead';

const HomePage = () => {
    return (
        <>
            
            <Fragment>
            <PageHead
                title="Lumina | Productos Estéticos"
                description="Distribuidora de productos para la Medicina Estética. Nos especializamos en la distribución de las mejores marcas de toxina botulínica proporcionando soluciones de compra rápidas y eficientes."
                keywords="Medicina Estética, Toxina Botulínica, Botox, Lumina Monterrey"
                image='../../images/logo.svg'
                url="https://luminaglow.mx"
                subject="Informacion sobre Lumina Glow"
            />
                <Navbar hclass={'wpo-site-header'} Logo={Logo} />
                <Hero hclass={'static-hero'} />
                {/* <AppointmentSection hclass={'appointment_section'}/> */}
                <ServiceSection hclass={"service_section section-padding"} />
                <About hclass={'about_section section-padding'} />
                <ProcessSection hclass={"work_section section-padding"} />
                {/* <ProjectSection hclass={'project_section section-padding'}/> */}
                <Testimonial tClass={'testimonial_section testimonial_section_slider'} />
                <CtaSection tClass={'cta_section'} />
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                {/* <TeamSection hclass={'team_section section-padding'}/> */}
                <FunFact hclass={'funfact_section'} />
                {/* <BlogSection tClass={'blog_section section-padding'}/>
          <CtafromSection hclass={'ctafrom_section'}/> */}
                <Footer hclass={'wpo-site-footer'} />
                <Scrollbar />
            </Fragment>
        </>

    )
};
export default HomePage;