import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img1 from '../../images/testimonial/1.jpg'
import Img2 from '../../images/testimonial/2.jpg'
import Img3 from '../../images/testimonial/3.jpg'
import SectionTitle from '../SectionTitle/SectionTitle';

const blueSky = 'https://images.pexels.com/photos/281260/pexels-photo-281260.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'


const testimonials = [
    {
        id: '01',
        img: blueSky,
        Des: "Tengo un tiempo comprando productos, toxina botulínica y fillers, todo excelente, excelente trato y costos.",
        title: 'Dra. Monse Muñoz',
        sub: "Content Writer",
    },
    {
        id: '02',
        img: blueSky,
        Des: "Excelente... siempre muy amable, mis productos siempre llegan a tiempo y super rápido y en buen estado. Nunca he tenido problemas con los productos... y si tengo duda siempre responde Siempre está al pendiente incluso del producto y de la llegada del producto.",
        title: 'Dra. Jocelyn Ibarra',
        sub: "Content Writer",
    },
    {
        id: '03',
        img: blueSky,
        Des: "100% recomendado, disponibilidad de material y entrega de productos, super accesible, además de promociones y super precios!",
        title: 'Dra. Pricila',
        sub: "Content Writer",
    },
    {
        id: '04',
        img: blueSky,
        Des: "Los precios de los productos esta super bien, además de que son de excelente calidad! Me encanta la toxica porque desde que compro con ustedes a mis pacientes les dura fácil los 6 meses! Sin duda me he convertido en cliente frecuente.",
        title: 'Dra. Blanca E.',
        sub: "Content Writer",
    },
    {
        id: '05',
        img: blueSky,
        Des: "Productos de confianza con respaldo de autenticidad. Entregas inmediatas sin demora. Muy buenos precios y promociones bastante llamativas.",
        title: 'Dr. Andrés J. Rosales',
        sub: "Content Writer",
    },


]




const Testimonial = (props) => {

    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        arrows: false,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                }
            },

        ]
    };


    return (

        <section className={"" + props.tClass}>
            <div className="container">
                <div className="row justify-content-left">
                    <div className="col-12">
                        <SectionTitle title='Testimonios' subtitle="¿Qué opinan nuestros clientes?" />
                    </div>
                </div>
                <div className="row testimonial_slider">
                    <Slider {...settings}>
                        {testimonials.map((testitem, titem) => (
                            <div className="testimonial_card" key={titem}>
                                <div className="icon">
                                    <i className="flaticon-quote"></i>
                                </div>
                                <ul>
                                    <li><i className="flaticon-star"></i></li>
                                    <li><i className="flaticon-star"></i></li>
                                    <li><i className="flaticon-star"></i></li>
                                    <li><i className="flaticon-star"></i></li>
                                    <li><i className="flaticon-star"></i></li>
                                </ul>
                                <p>{testitem.Des}</p>
                                <div className="ath">
                                    <div className="image">
                                        <img src={testitem.img} alt="Cliente de Lumina Glow" />
                                    </div>
                                    <div className="text">
                                        <h3 aria-label={testitem.title}>{testitem.title}</h3>
                                        <span>{testitem.sub}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;







