import { useState, useEffect } from 'react';


export default function WspButton() {
    const [isVisible, setIsVisible] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (isVisible) {
            const timer = setTimeout(() => {
                setIsLoaded(true);
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            setIsLoaded(false);
        }
    }, [isVisible]);

    const toggleWhatsapp = () => {
        setIsVisible(!isVisible);
    };

    return (
        <>
            <div>
                <div>
                    <div className="whatsapp-main">
                        <div
                            className={`whatsapp-wrapper ${isVisible ? 'show-whatsapp' : 'hide-whatsapp'}`}
                            style={{ width: '360px' }}
                        >
                            <div
                                className="close_whatsapp"
                                role="button"
                                tabIndex="0"
                                onClick={toggleWhatsapp}
                            ></div>
                            <div className="whatsapp-header">
                                <div className="support-avatar" size="52">
                                    <div className="avatar-wrapper">
                                        <div className="avatar-img"></div>
                                    </div>
                                </div>
                                <div className="header-info">
                                    <div className="whatsapp-name" style={{ fontWeight: 'bold' }}>LUMINA GLOW</div>
                                    <div className="whatsapp-reply-time" style={{ fontSize: '12px', color: "grey" }}>Usualmente responde en pocas horas</div>
                                </div>
                            </div>
                            <div className="whatsapp-background">
                                <div className="whatsapp-message-container">
                                    {!isLoaded ? (
                                        <div className="loading-animation">
                                            <div className="dot-pulse"></div>
                                        </div>
                                    ) : (
                                        <div className="whatsapp-message-wrapper" style={{ display: isLoaded ? 'flex' : 'none' }}>
                                            <div className="chat-body-wrapper">
                                                <div className="whatsapp-chat-name">LUMINA GLOW</div>
                                                <div className="whatsapp-chat-message">
                                                    <p>¡Bienvenido a nuestro sitio!👋</p><br />
                                                    <p>
                                                        Nuestro objetivo es ayudarte a tomar la mejor decisión para ti. <br /> No dudes en contactarnos
                                                        si tienes alguna pregunta. ¡Gracias por visitarnos!
                                                    </p>
                                                </div>
                                                <div className="whatsapp-chat-time">20:49</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <a
                                role="button"
                                href="https://api.whatsapp.com/send/?phone=5218123191534&text&type=phone_number&app_absent=0"
                                title="WhatsApp"
                                className="whatsapp-btn"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label='Link a whatsapp'
                            >
                                <svg
                                    className="whatsapp-send-icon-small"
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1"
                                    height="20"
                                    viewBox="0 0 90 90"
                                >
                                    <path
                                        d="M90 44a44 44 0 0 1-66 38L0 90l8-24A44 44 0 0 1 46 0c24 0 44 20 44 44zM46 7C25 7 9 24 9 44c0 8 2 15 7 21l-5 14 14-4a37 37 0 0 0 58-31C83 24 66 7 46 7zm22 47l-2-1-7-4-3 1-3 4h-3c-1 0-4-1-8-5-3-3-6-6-6-8v-2l2-2 1-1v-2l-4-8c0-2-1-2-2-2h-2l-3 1c-1 1-4 4-4 9s4 11 5 11c0 1 7 12 18 16 11 5 11 3 13 3s7-2 7-5l1-5z"
                                        fill="#FFF"
                                    />
                                </svg>
                                <span className="chat-button-text">Iniciar Chat</span>
                            </a>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
                        </div>
                        <div className="whatsapp-popup-button" id="whatsapp-popup" onClick={toggleWhatsapp}>
                            <div className="whatsapp-notify"></div>
                            <div className="whatsapp-button-main">
                                <svg
                                    className="whatsapp-send-icon-big"
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1"
                                    width="32"
                                    height="32"
                                    viewBox="0 0 90 90"
                                >
                                    <path
                                        d="M90 44a44 44 0 0 1-66 38L0 90l8-24A44 44 0 0 1 46 0c24 0 44 20 44 44zM46 7C25 7 9 24 9 44c0 8 2 15 7 21l-5 14 14-4a37 37 0 0 0 58-31C83 24 66 7 46 7zm22 47l-2-1-7-4-3 1-3 4h-3c-1 0-4-1-8-5-3-3-6-6-6-8v-2l2-2 1-1v-2l-4-8c0-2-1-2-2-2h-2l-3 1c-1 1-4 4-4 9s4 11 5 11c0 1 7 12 18 16 11 5 11 3 13 3s7-2 7-5l1-5z"
                                        fill="#FFF"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}