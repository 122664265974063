import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Homepage from '../HomePage/HomePage'
import AboutPage from '../AboutPage/AboutPage';
import ShopPage from '../ShopPage'
import ProductSinglePage from '../ProductSinglePage';
import OrderRecived from '../OrderRecived';
import FaqPage from '../FaqPage/FaqPage';
// import ProjectPage from '../ProjectPage/ProjectPage';
// import ServicePages from '../ServicePage/ServicePage';
import ContactPage from '../ContactPage/ContactPage';
import ErrorPage from '../ErrorPage/ErrorPage';


const AllRoute = () => {

  return (
    <div className="App"  style={{width: '100%'}}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          {/* <Route path="about" element={<AboutPage />} /> */}
          <Route path="shop" element={<ShopPage />} />
          <Route path='shop-single/:slug' element={<ProductSinglePage />} />
          <Route path='order_received' element={<OrderRecived />} />
          <Route path="faq" element={<FaqPage/>} />
          {/* <Route path="services" element={<ServicePages/>} /> */}
          {/* <Route path="project" element={<ProjectPage/>} /> */}
          <Route path='contact' element={<ContactPage />} />
          <Route path='404' element={<ErrorPage />} /> 
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
