import React from 'react';
import { Link } from 'react-router-dom';
import VideoModal from '../ModalVideo/VideoModal';

// image
import Himg1 from '../../images/slider/1.png'
import Hshape from '../../images/slider/shape.svg'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const hero = () => {
    return (
        <section className="hero_section">
            <div className="bg_shape">
                <svg viewBox="0 0 1920 1075" fill="none">
                    <path d="M0 0H1920V1000C1920 1000 1632 619 962 917C292 1215 0 1000 0 1000V0Z" fill="#EBF7FF" />
                </svg>
            </div>
            <div className="content">
                <h2>Calidad que transforma resultados</h2>
                <h3>Productos Estéticos <br></br>
                    de Alta Calidad</h3>
                <p> Respaldados por la confianza de clínicas y médicos de todo el país. 
                    Nuestro compromiso es ofrecer productos innovadores y seguros que potencien los resultados de sus tratamientos.</p>
                <Link onClick={ClickHandler} className="theme-btn" to="/shop" aria-label='Productos'>Lumina Glow Monterrey</Link>
            </div>
            <div className="image_content">
                {/* <div className="video">
                    <VideoModal />
                </div> */}
                <div className="image">
                    <img src={'https://d1m57hjtnyqfpw.cloudfront.net/memorex.png'} alt="Doctor de Lumina Glow Monterrey" />
                    <div className="bg_shape_2">
                        <img src={Hshape} alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default hero;