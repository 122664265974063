import React from 'react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { useHref } from 'react-router-dom';

const Product = ({ item, addToCart }) => {

  return (

    <div className="row"  style={{marginRight: '0px', marginLeft:'0px'}}>
      <div className="col col-lg-5 col-12">
        <div className="shop-single-slider">
          <div className="slider-nav">
            <div>
              <Zoom>
                <img src={item.proImg ? item.proImg : ''} alt={item.title} />
              </Zoom>
            </div>
          </div>
        </div>
      </div>
      <div className="col col-lg-7 col-12">
        <div className="product-details">
          <h2>{item.title}</h2>
          <div className="price">
            <span className="current">${item.price}</span>
            {/* <span className="old">${item.delPrice}</span> */}
          </div>
          <p>{item.description}</p>
          <ul>
          </ul>
          <div className="product-option">
            <div className="product-row">
              <a href="https://api.whatsapp.com/send/?phone=5218123191534&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer" aria-label='Contact Lumina Glow'>
                <button className="theme-btn">Comprar Ahora</button>
              </a>
              <div>
              </div>
            </div>
          </div>
          <div className="tg-btm">
            <p><span>Categoría:</span>{item.brand}</p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Product;
