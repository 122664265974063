import React from 'react';
import AllRoute from '../router'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WspButton from '../../components/WSP/WspButton';

const App = () => { 

  return (
    <div className="App" id='scrool' style={{width: '100%'}}>
          <AllRoute/>
          <ToastContainer/>
          <WspButton />
    </div>
  );
}

export default App;