import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';

/* image */
import Pross1 from '../../images/work/1.jpg'
import Pross2 from '../../images/work/2.jpg'
import Pross3 from '../../images/work/3.jpg'
import Pross4 from '../../images/work/4.jpg'
import Shape from '../../images/work/shape.svg'

const ProcessSection = (props) => {
    return (
        <section className={"" + props.hclass}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9 col-12">
                        <SectionTitle title={'Entregas en Monterrey el mismo día'} subtitle={'Nuestro Proceso de Trabajo'}/>
                    </div>
                </div>
                <div className="work_wrapper">
                    <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-12">
                            <div className="work_card">
                                <div className="image">
                                    <img src={'https://images.pexels.com/photos/7653125/pexels-photo-7653125.jpeg'} alt="Doctor de Lumina Glow Monterrey" />
                                        <span className="number">01</span>
                                </div>
                                <div className="text">
                                    <h3>Explora nuestro catálogo</h3>
                                    <p>Explora nuestro catálogo y elige los que mejor se adapten a tus necesidades.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-12">
                            <div className="work_card">
                                <div className="image">
                                    <img src={'https://images.pexels.com/photos/789822/pexels-photo-789822.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} alt="Contacto de Lumina Glow Monterrey" />
                                        <span className="number">02</span>
                                </div>
                                <div className="text">
                                    <h3>Contactáctanos</h3>
                                    <p>Comunícate con nosotros para cualquier consulta. Estamos disponibles 24/7</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-12">
                            <div className="work_card">
                                <div className="image">
                                    <img src={'https://images.pexels.com/photos/7843980/pexels-photo-7843980.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} alt="Productos de Lumina Glow Monterrey" />
                                        <span className="number">03</span>
                                </div>
                                <div className="text">
                                    <h3>Proceso de envío</h3>
                                    <p>Envíos al instante el mismo día en Monterrey, Nuevo León.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-12">
                            <div className="work_card">
                                <div className="image">
                                    <img src={'https://images.pexels.com/photos/4226269/pexels-photo-4226269.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} alt="Productos de Lumina Glow Monterrey" />
                                        <span className="number">04</span>
                                </div>
                                <div className="text">
                                    <h3>Recibe tus productos</h3>
                                    <p>Recibe tus productos en la fecha y hora programada. Te garantizamos una entrega rápida, segura y en frío.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape">
                        <img src={Shape} alt="Figura de apoyo" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProcessSection;